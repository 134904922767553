import { PRICE_UNITS, PRICE_UNITS_ENUM_VALUES, VIEW_OPTIONS } from '@scripts/enums/products';
import { colors, scale, typography } from '@scripts/gds';
import { formatPrice } from '@scripts/helpers';

const PriceItem = ({
    price,
    old_price,
    percentEconomy,
    priceUnit,
    activeView,
    vat,
}: {
    price: number;
    old_price?: number;
    percentEconomy?: number;
    priceUnit: PRICE_UNITS;
    activeView?: string;
    vat?: number;
}) => (
    <div css={{ marginBottom: scale(1, false, 6) }}>
        {old_price && old_price > price && (
            <div>
                <span
                    css={{
                        marginRight: scale(1),
                        color: colors.actionRed,
                        ...typography('bodyXSRegular'),
                        position: 'relative',
                        '&::before': {
                            content: "''",
                            position: 'absolute',
                            top: '50%',
                            left: '-1px',
                            height: '1px',
                            width: 'calc(100% + 2px)',
                            backgroundColor: colors.actionRed,
                        },
                        ...(activeView === VIEW_OPTIONS.TABLE && { ...typography('small'), lineHeight: 1.6 }),
                    }}
                >
                    {formatPrice(Number(old_price))} ₽/шт
                </span>
            </div>
        )}
        <div
            css={{
                ...(!(old_price && old_price > price) && { paddingTop: scale(5, true) }),
                ...(!vat && { paddingBottom: scale(5, true) }),
                ...typography('bodyLgBold'),
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: colors.textMainBlack,
                ...((activeView === VIEW_OPTIONS.LINES || activeView === VIEW_OPTIONS.TABLE) && {
                    justifyContent: 'unset',
                    gap: scale(1),
                }),
                ...(activeView === VIEW_OPTIONS.TABLE && { ...typography('tableBold') }),
            }}
        >
            <p>
                {formatPrice(price)}{' '}
                <span css={{ whiteSpace: 'nowrap' }}>
                    ₽/
                    <span
                        dangerouslySetInnerHTML={{ __html: PRICE_UNITS_ENUM_VALUES[priceUnit] }}
                        css={{
                            ...(activeView === VIEW_OPTIONS.TABLE && {
                                ...typography('tableBold'),
                                fontWeight: 500,
                                color: colors.textSecondGrey,
                            }),
                        }}
                    />
                </span>
            </p>
            {percentEconomy !== undefined && percentEconomy < 0 && old_price && (
                <div
                    css={{
                        backgroundColor: colors.textMainBlack,
                        color: colors.white,
                        borderRadius: '43px',
                        ...typography('personalDiscount'),
                        padding: `${scale(1, true)}px ${scale(1)}px`,
                    }}
                >
                    {percentEconomy}%
                </div>
            )}
        </div>
        {vat !== undefined && vat !== 0 && (
            <div
                css={{
                    ...typography('bodyXXSMedium'),
                    lineHeight: 1.6,
                }}
            >
                НДС: {vat} %
            </div>
        )}
    </div>
);

export default PriceItem;
