import { scale } from '@greensight/gds';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import { ProductImage } from '@api/catalog';

import Loader from '@components/controls/Loader';

import { transformImageUrl } from '@scripts/helpers';

import classes from './HoverGallery.module.scss';
import { HoverGalleryProps, ImagesStatuses } from './types';

export const HoverGallery = ({ style, pics, titleImage }: HoverGalleryProps) => {
    const [pagesQnt, setPagesQnt] = useState(0);
    const [page, setPage] = useState(1);
    const [isPaginatorShown, setIsPaginatorShown] = useState(true);

    const component = useRef<HTMLDivElement | null>(null);
    const picsQnt = pics.length;
    const MAX_PAGES = 5;

    const [imagesLoadStatuses, setImagesLoadStatuses] = useState<ImagesStatuses>(
        Array.from({ length: MAX_PAGES }, () => false)
    );

    const setImageStatus = (index: number, status: boolean) => {
        setImagesLoadStatuses(prev => {
            const newStatuses = [...prev];
            newStatuses[index] = status;
            return newStatuses;
        });
    };

    const noPhotoLoaded = imagesLoadStatuses.every(el => el === false);

    const mouseMove = debounce((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (component.current) {
            const componentElement = component.current;
            const componentBounding = componentElement.getBoundingClientRect();
            const componentWidth = componentElement.offsetWidth;
            const componentX = componentBounding.x;
            const mouseX = event.clientX - componentX;
            const partWidth = componentWidth / pagesQnt;
            let pageNumber = Math.floor(mouseX / partWidth) + 1;
            if (pageNumber > MAX_PAGES) {
                pageNumber = MAX_PAGES;
            }
            if (pageNumber < 1) {
                pageNumber = 1;
            }

            setPage(pageNumber);
        }
    }, MAX_PAGES);

    const extractLinksFromPics = (pics: ProductImage[]) => {
        const arrLen = pics.length;
        const defaultLinks = Array(arrLen).fill(pics[pics.length - 1]?.url || pics[pics.length - 1]?.url || null);
        return pics
            .map(el => el.url)
            .concat(defaultLinks)
            .slice(0, arrLen);
    };

    const picsLinks = extractLinksFromPics(pics);

    const showIndicator = isPaginatorShown && !noPhotoLoaded && picsQnt > 0;

    useEffect(() => {
        const qnt = picsQnt >= MAX_PAGES ? MAX_PAGES : picsQnt < 1 ? 0 : picsQnt;
        setPagesQnt(qnt);
    }, [pics]);

    return (
        <>
            <div
                onMouseMove={mouseMove}
                onMouseEnter={() => {
                    setIsPaginatorShown(true);
                }}
                onMouseLeave={() => {
                    setTimeout(() => {
                        setPage(1);
                    }, 10);
                }}
                className={classes.HoverGallery}
                style={style}
            >
                <div ref={component} className={classes.Img}>
                    {picsLinks.map((link, index) => {
                        const isImageLoaded = imagesLoadStatuses[index];
                        return (
                            <Fragment key={link}>
                                {!isImageLoaded && (
                                    <div
                                        className={classNames('custom-loader', {
                                            [classes.Hidden]: page !== index + 1,
                                        })}
                                    >
                                        <Loader />
                                    </div>
                                )}
                                <img
                                    style={{
                                        objectFit: 'contain',
                                        objectPosition: 'center center',
                                    }}
                                    key={index}
                                    alt={titleImage}
                                    title={titleImage}
                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                                        setImageStatus(index, false);
                                    }}
                                    onLoad={() => {
                                        setImageStatus(index, true);
                                    }}
                                    className={classNames({ [classes.Hidden]: page !== index + 1 })}
                                    src={transformImageUrl(picsLinks[index], scale(20), scale(23))}
                                />
                            </Fragment>
                        );
                    })}

                    {(noPhotoLoaded || pics.length === 0) && <div css={{ backgroundColor: '#fff' }} />}
                </div>
            </div>
            {showIndicator && pics.length > 1 && (
                <div
                    className={classes.Indicator}
                    style={{
                        marginLeft: `calc((100% - ${pagesQnt * 32}px) / 2)`,
                        gap: '2px',
                    }}
                >
                    {Array.from({ length: pagesQnt }).map((_, index) => (
                        <div
                            key={index}
                            className={classNames(classes.IndicatorBar, {
                                [classes.Active]: page === index + 1,
                            })}
                        ></div>
                    ))}
                </div>
            )}
        </>
    );
};
